export const contacts: TableModule = {
    name: "contacts",
    route: "spa.contacts",
    columns: [
        {
            name: "id",
            sortable: true
        },
        {
            name: "customName",
            sortable: true
        },
        {
            name: "email",
            sortable: false
        }
    ]
}