export const costCenters: TableModule = {
    name: "costCenters",
    route: "spa.baseData.costCenters",
    columns: [
        {
            name: "name",
            sortable: true,
            mobile: true,
            pageLink: true
        },
        {
            name: "identifier",
            sortable: true
        },
        {
            name: "status",
            sortable: true,
            mobile: true,
            width: "w-[100px]"
        }
    ],
    filters: [
        {
            name: "name",
            column: "cost_centers.name",
            type: "string"
        },
        {
            name: "identifier",
            column: "cost_centers.identifier",
            type: "string"
        },
        {
            name: "status",
            column: "cost_centers.status",
            type: "select",
            options: [
                {
                    value: true,
                    label: "Aktiv"
                },
                {
                    value: false,
                    label: "Inaktiv"
                }
            ]
        }
    ],
    quickFilters: [
        {
            name: "active",
            column: "cost_centers.status",
            operator: "=",
            value: true,
            default: true
        }
    ],
    tools: [
        {
            name: "costCenters.create",
            isCreateAction: true,
            ability: ['create', 'costCenters']
        },
        {
            name: "costCenters.status",
            color: "success",
            icon: "heroicons:check-circle"
        },
        {
            name: "costCenters.delete",
            color: "rose",
            icon: "heroicons:trash"
        },
        {
            name: "general.export",
            icon: "heroicons:arrow-down-tray"
        }
    ]
}